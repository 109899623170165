<!-- 首页第三方客服 -->
<template>
  <page-container class="customer-service-container">
    <div>
      <p><span>订单发货物流咨询</span><br>周一至周日 09:00-17:00 接待</p>
      <van-button round type="primary" @click="toService1">点击打开客服会话</van-button>
    </div>
    <div>
      <p><span>系统障碍</span><br>周一至周日 09:00-17:00 接待</p>
      <van-button round type="primary" @click="toService2">点击打开客服会话</van-button>
    </div>
    <div>
      <p><span>信息咨询</span><br>周一至周日 09:00-17:00 接待</p>
      <van-button round type="primary" @click="toService3">点击打开客服会话</van-button>
    </div>
    <div>
      <p><span>资料收集</span><br>周一至周日 09:00-17:00 接待</p>
      <van-button round type="primary" @click="toService4">点击打开客服会话</van-button>
    </div>

  </page-container>
</template>

<script>
export default {
  name: 'customerServiceOther',
  methods: {
    toService1() {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfc7c294f1496719f6d';
    },
    toService2() {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfcb0e66e6a528025d7';
    },
    toService3() {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfc8de96c6f860c93b4';
    },
    toService4() {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfc9fc5fa5af4817e01';
    },

    toService() {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfc7c294f1496719f6d';
      // window.open('https://work.weixin.qq.com/kfid/kfc7c294f1496719f6d');
    }
  },
};
</script>

<style lang="less" scoped>
.customer-service-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    width: 100%;
    text-align: center;
    word-break: break-all;
  }

  & > div + div {
    margin-top: 50px;
  }

  p {
    margin-bottom: 5px;
    font-size: 18px;
    color: #333;

    & > span {
      line-height: 40px;
      color: @primary-color;
      font-weight: bolder;
    }
  }

  .van-button {
    height: 38px;
    font-size: 16px;
  }
}
</style>
